import { StyleSheet } from 'react-native'
import { COLORS } from '../../assets'

export default StyleSheet.create({
	title: {
		fontSize: 20,
		fontWeight: 'bold',
		marginBottom: 15
	}
})
