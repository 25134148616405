import { StyleSheet } from 'react-native'

export default StyleSheet.create({
	icon: {
		marginRight: 15,
	},
	touch: {
		marginLeft: 20,
	},
})
