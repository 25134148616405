export const COLORS = {
	WHITE: '#FFFFFF',
	BLACK: '#000000',
	GREY: '#9E9E9E',
	GREY_SEC: '#C6C6C6',
	GREY_DARK: '#787777',
	GREEN_PRIM: '#008120',
	GREEN_SEC: '#006E1B',
	GREEN_BUTTON: '#04741C',
	BLUE: '#4193E8',
	BLUE_SEC: '#6DA2F7',
	YELLOW: '#D8C750',
	RED: '#D9534F',
	ORANGE: '#FA8D1F',
}
